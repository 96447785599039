.grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50px 1fr;
    grid-template-areas: 
        "hd"
        "bd"
    ;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    max-height: 100vh;
    min-height: 100vh;

    &__header {
        grid-area: hd;
    }

    &__body {
        grid-area: bd;
        display: flex;
        overflow: hidden;
    }

    &__body-container {
        width: 100%;
    }

    &__main {
        display: flex;
        height: 100%;
        position: relative;
    }

    &__live-event {
        grid-area: le;
        background-color: $black;
    }

    &__side-bar {
        flex-shrink: 0;
        width: 375px;
        background-color: $purple;
        position: relative;
        color: $white;
        padding: 40px;
        box-sizing: border-box;
        overflow: auto;
        font-weight: 300;

        & h1 {
            font-family: HeyWow;
            font-weight: bold;
            font-size: 3.2rem;
            line-height: 1.1;
            margin-top: 0;
            position: relative;
        }

        & p {
            font-size: 1.4rem;
            line-height: 1.5;
            position: relative;
            margin-bottom: 50px;
            font-weight: 300;
        }

        // &:before {
        //     content: ' ';
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //     left: 0;
        //     height: 150px;
        //     background: linear-gradient(to bottom right, #842C73 50%, $blue 50%);
        // }
    }

    &__content {
        width: 100%;
        overflow: auto;
        background-color: #2BA7DA;
    }

    &__resources {
        flex-shrink: 0;
        background-color: #fff;
        height: 100%;
        position: relative;
        border-top: 1px solid #fff;

        &--mobile {
          box-shadow: 0 0 20px rgba(0,0,0,.25);
        }

        & h2 {
            font-family: HeyWow;
            font-weight: bold;
            font-size: 2.6rem;
            line-height: 1.1;
            margin-top: 0;
            position: relative;
            margin-bottom: 5px;
        }

        & h3 {
            font-family: HeyWow;
            font-weight: bold;
            font-size: 2rem;
            margin-bottom: 5px;
            margin-top: 30px;
        }

        & p {
            font-size: 1.4rem;
            line-height: 1.5;
            position: relative;
            margin-top: 5px;
        }
    }
}

@media (max-width: 1300px) {
    .grid {
        &__side-bar {
            width: 325px;
            padding: 25px;
        }

        &__resources {
            &:before {
                height: 0px;
            }
        }
    }
}

@media (max-width: $mobile) {
    .grid {
        // grid-template-columns: 1fr;
        // grid-template-rows: 50px 50px 1fr;
        // grid-template-areas: 
        //     "hd"
        //     "nv"
        //     "bd"
        // ;
        display: block;
        position: static;
        top: auto;
        right: auto;
        left: auto;
        bottom: auto;
        max-height: 100%;

        &__header {
            height: 50px;
        }

        &__main {
            display: block;
            overflow: auto;
        }

        &__side-bar {
            width: 100%;
        }

        &__content {
            padding-bottom: 100px;
        }

        &__resources {
            height: 70px;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 2;
            overflow: auto;
        }
    }
}

@media (max-height: 980px) {
    .grid {
        &__resources {
            &:before {
                height: 0px;
            }
        }
    }
}