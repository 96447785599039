.header {
    background-color: #FFFFFF;
    color: #000000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2.2rem;
    font-weight: bold;
    font-family: HeyWow;

    &__separator {
        font-weight: normal;
        display: inline-block;
        margin: 0 10px;
    }

    &__logo {
        font-family: Gotham SSm A,Gotham SSm B,Helvetica;
        height: 100%;
        width: 90px;
        background-color: #000000;
        color: #FFFFFF;
        font-size: 2.2rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        -webkit-font-smoothing: antialiased;
    }
}

@media (max-width: $mobile) {
    .header {
        font-size: 1.8rem;
        display: flex;
        justify-content: flex-start;
        margin-left: 0px;

        &__logo {
            height: 100%;
            width: 90px;
            background-color: #000000;
            color: #FFFFFF;
            font-size: 2.2rem;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
            -webkit-font-smoothing: antialiased;
        }
    }
}

@media (max-width: 320px) {
    .header {
        font-size: 1.6rem;
    }
}