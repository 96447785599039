.thankyou {
  &__container {
    position: relative; 
    text-align: center; 
    margin: 0px auto; 
    padding-top: 50px; 
    width: 400px;
  }

  & h2 {
    font-family: HeyWow;
    font-weight: bold;
    font-size: 36px;
    text-align: center;
    margin-bottom: 15px;
  }

  & p {
    text-align: center;
    margin: 0px;
  }

  & a {
    color: $green;
  }

  &__left-image {
    position: absolute; 
    top: 50px; 
    left: -45px; 
    width: 100px; 
    height: auto;
  }

  &__right-image {
    position: absolute; 
    top: 50px; 
    right: -50px; 
    width: 90px; 
    height: auto;
  }
}