.live-event {
    color: $white;
    font-size: 1.4rem;
    overflow: auto;
    display: flex;
    justify-content: space-between;

    & p:first-child {
        padding: 10px 0 10px 40px;
        margin: 0;
        font-weight: 300;
    }

    &__strong {
        font-weight: bold;
        color: $yellow;
        text-transform: uppercase;
    }

    &__countdown {
        display: flex;
        font-size: 2rem;
        font-weight: bold;
        margin-left: 15px;
        padding: 10px 30px 10px 0;
        align-items: flex-start;
    }

    &__time {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:first-child {
            margin: 0 3px;
        }

        & span {
            font-size: 0.9rem;
            font-weight: normal;
            text-transform: uppercase;
        }
    }

    &__item-container {
        display: flex;
        align-items: center;
    }

    &__btn {
        background-color: $yellow;
        color: $black;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        font-size: 1.6rem;
        font-weight: bold;
        text-transform: uppercase;
        padding: 0 15px;
        cursor: pointer;
        margin-left: 15px;
        text-decoration: none;

        &:visited {
            color: $black;
            text-decoration: none;
        }

        &:active {
            color: $black;
            text-decoration: none;
        }

        &:hover {
            color: $black;
            text-decoration: none;
        }

        & svg {
            margin-left: 10px;
            padding-bottom: 2px;
        }
    }
}

@media (max-width: $mobile) {
    .live-event {
        font-size: 1.2rem;

        & p:first-child {
            padding: 10px 0 10px 15px;
        }

        &__countdown {
            padding: 10px 15px 10px 0;
        }
    }
}