.modal {

    &__overlay {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba(0,0,0,0.75);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: auto;
        padding: 25px;
        z-index: 500;
    }

    &__body {
        position: relative;
        max-width: 800px;
        border: 1px solid rgb(204, 204, 204);
        background: rgb(255,255,255);
        overflow: auto;
        border-radius: 4px;
        outline: none;
        padding: 80px;
        font-size: 1.6rem;
        box-sizing: border-box;
        margin: auto;
        font-weight: 300;
        z-index: 501;
    }

    &__close-btn {
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
    }

    &__list-label {
        margin-bottom: 5px;

        & + ul {
            margin-top: 0px;
        }
    }

    & button {
        border: 2px solid #E2E2E2;
        width: 100%;
        background-color: rgba(0,0,0,0);
        color: $green;
        font-size: 1.6rem;
        font-weight: bold;
        padding: 15px;
        cursor: pointer;
        border-radius: 3px;
        text-align: left;
        transition: 
            border 300ms ease-in-out,
            background-color 300ms ease-in-out, 
            color 300ms ease-in-out;

        &:hover {
            background-color: $green;
            border: 2px solid $green;
            color: $white;
        }
    }

    & h3 {
       font-size: 3rem;
       font-weight: bold;
       color: $black;
       line-height: 1.1;
       margin-top: 0;
    }

    & h4 {
        margin-bottom: 0px;

        & + p {
            margin-top: 5px;
        }
    }

    & p {
        line-height: 1.7;
        font-size: 1.4rem;
    }

    & a {
        color: $green;
        text-decoration: none;
        font-weight: bold;

        &:visited {
            color: $green;
            text-decoration: none;
        }

        &:active {
            color: $green;
            text-decoration: none;
        }

        &:hover {
            color: $green;
            text-decoration: none;
        }
    }

    & ul {
        font-size: 1.4rem;
        line-height: 1.7;
    }
}

.schedule {
    .modal button {
        border: none;
        width: 100%;
        background-color: rgba(0,0,0,0);
        color: #F37721;
        font-size: 1.5rem;
        font-weight: bold;
        cursor: pointer;
        padding: 0px;
        text-align: left;
        margin-bottom: 20px;

        &:hover {
            background-color: rgba(0,0,0,0);
            color: #F37721;
        } 
    }
}

@media (max-width: $mobile) {
    .modal {
        &__overlay {
            padding: 20px;
        }

        &__body {
            padding: 50px 25px 25px 25px;
        }

        &__close-btn {
            top: 15px;
            right: 15px;
        }

        & h3 {
            font-size: 2.6rem;
        }
    }
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}