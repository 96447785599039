$white: #FFFFFF;
$black: #000000;
$lightgray: #E0E0E0;
$offwhite: #F0F0F0;
$magenta: #EC008B;
$yellow: #FEF102;

$mobile: 950px;

$blue: #075EB1;
$light-blue: #2BA7DA;

$purple: #6C419B;
$light-purple: #CEBFDE;
$green: #41A29B;