.checklist {
    &__container {
        &--top {
            width: 100vw;
            min-height: 65vh;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        
        &--bottom {
            background-color: #000000;
            min-height: 35vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 20px;

            &-inner {
                max-width: 650px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 20px 0;
            }

            & p {
                color: #FFFFFF;
                font-size: 20px;
                text-align: center;
                font-weight: lighter;
            }
        }
    }

    &__bg {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: -1;
        display: flex;
        flex-wrap: wrap;

        &--top-left {
            flex-basis: 50%;
            background-color: #213F99;
        }

        &--top-right {
            flex-basis: 50%;
            background: linear-gradient(to bottom right, #00A249 50%, #EC008B 50%);
        }
    }

    &__header {
        color: #FFFFFF;
        text-transform: uppercase;
        text-align: center;
        font-size: 80px;
        font-weight: bold;
        margin: 0;

        & span {
            white-space: nowrap;
            font-family: HeyWow;

            &:first-child {
                font-size: 35px;
                font-family: Gotham SSm A,Gotham SSm B,Helvetica;
            }
        }

        &--sub {
            color: #FFFFFF;
            text-transform: uppercase;
            text-align: center;
            font-size: 35px;
            font-weight: bold;
            margin: 0;
        }
    }

    &__desc {
        color: #FFFFFF;
        font-weight: bold;
        font-size: 22px;
    }

    &__btn {
        background-color: #FEF102;
        color: #000000;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: bold;
        border: none;
        padding: 18px;
        cursor: pointer;
        margin-top: 25px; 
        text-decoration: none;

        &:hover {
            text-decoration: none;
            color: #000000;
        }

        &:visited {
            text-decoration: none;
            color: #000000;
        }

        &:active {
            text-decoration: none;
            color: #000000;
        }
    }
}


/* ############### MEDIAL QUERIES ############### */


@media (max-width: 580px) {
    .checklist {
        &__header {
            font-size: 42px;

            & span:first-child {
                font-size: 22px;
            }

            &--sub {
                font-size: 22px;
            }
        }

        &__container {
            &--bottom p {
                font-size: 16px;
            }
        }

        &__btn {
            font-size: 16px;
        }
    }
}