.presentations {
    background-color: #FFF;

    &__veil {
      background-color: rgba(33, 63, 153, .95);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      color: #FFF;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      & > div {
        max-width: 510px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }

      & h1 {
        font-family: HeyWow;
        font-weight: bold;
        font-size: 3.2rem;
        line-height: 1.1;
        margin-top: 0;
        position: relative;
        margin-bottom: 15px;
      }

      & p {
        font-size: 1.4rem;
        line-height: 1.5;
        position: relative;
        margin: 0;
        font-weight: 300;
      }

      & a {
        color: $light-blue;
        font-weight: bold;
        text-decoration: none;
      }
    }

    &__img {
      width: 100%;
      max-height: 570px;
      object-fit: cover;
    }

    &__mobile-img {
      width: 100%;
      height: auto;
    }

    &--last-page {
        background-color: #FFF !important;
    }
    
    &__bottom {
        background-color: $light-purple;
        padding: 6%;
    }

    &__link {
        font-size: 1.6rem;
        color: $white;
        text-decoration: none;
        display: flex;
        align-items: center;
        background-color: rgba(255, 255, 255 ,0.10);
        border-radius: 3px;
        padding: 20px 15px;
        margin-bottom: 20px;
        font-weight: 500;
        transition: 
            background-color 300ms ease-in-out, 
            color 300ms ease-in-out,
            border 300ms ease-in-out;

        &:hover {
            background-color: rgba(255, 255, 255 , 1);
            color: #000;
        }

        &:hover .presentations__link-icon {
            border: 2px solid $black;
            background: $black;
            color: #fff;
        }

        &:hover .presentations__link-icon--hello {
            border: 2px solid $black;
            background: $black;
            color: #fff;
        }
    }

    &__link-container {
        background-color: rgba(255, 255, 255 ,0.10);
        border-radius: 3px;
        padding: 20px 15px;
        margin-bottom: 20px;

        &:last-child {
            border-bottom: none;
            margin-bottom: 60px;
        }
    }

    &__link-icon {
        border: 2px solid $white;
        background-color: $purple;
        font-size: 2rem;
        border-radius: 50px;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin-right: 10px;
        font-family: HeyWow;
        transition: 
            background-color 300ms ease-in-out, 
            color 300ms ease-in-out;

        &--hello {
            border: 2px solid $white;
            background-color: $purple;
            color: #fff;
            font-size: 1.1rem;
            border-radius: 50px;
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            margin-right: 10px;
            font-family: HeyWow;
            transition: 
                background-color 300ms ease-in-out, 
                color 300ms ease-in-out;
        }

        &--loc {
            border: 2px solid $light-blue;
            color: #fff;
            font-size: 2rem;
            border-radius: 50px;
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            margin-right: 10px;
            font-family: HeyWow;
            transition: 
                background-color 300ms ease-in-out, 
                color 300ms ease-in-out;
        }
    }

    &__selected {
        background-color: rgba(255, 255, 255 , 1);
        color: #000;

        &::after {
            content: '';
            position: absolute;
            right: 25px;
            width: 0px;
            height: 0px;
            border-top: 15px solid transparent;
            border-bottom: 15px solid transparent;
            border-left: 15px solid #fff;
        }

        & .presentations__link-icon {
            border: 2px solid $black;
            background: $black;
            color: #fff;
        }

        & .presentations__link-icon--hello {
            border: 2px solid $black;
            background: $black;
            color: #fff;
        }
    }
    
    &__mobile-bg {
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        background-color: $purple;
        position: relative;
        color: $white;
        padding: 20px 20px 80px 20px;
        box-sizing: border-box;
        overflow: auto;
        min-height: 100vh;

        &--veil {
          height: 100vh;
        }

        & h1 {
            font-family: HeyWow;
            font-size: 3.2rem;
            line-height: 1.1;
            margin-top: 0;
            position: relative;
        }

        & p {
            font-size: 1.4rem;
            line-height: 1.5;
            position: relative;
            font-weight: 300;
        }

        // &:before {
        //     content: ' ';
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //     left: 0;
        //     height: 150px;
        //     background: linear-gradient(to bottom right, #842C73 50%, $blue 50%);
        // }
    }

    &__embed-container {
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;
        padding-top: 56.25%;

        &:before {
            display: block;
            content: "";
        }
    }

    &__embed-item {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    & h2 {
        font-family: HeyWow;
        font-weight: bold;
        font-size: 3.2rem;
        line-height: 1.1;
        margin-top: 0;
    }

    & p {
        font-size: 1.4rem;
        line-height: 1.5;
    }

    &__next-btn {
        background-color: $black;
        font-size: 1.6rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 50px 20px 20px;
        cursor: pointer;
        text-decoration: none;
        color: $white;
        border-radius: 3px;
        transition: background-color 200ms ease-in-out, color 200ms ease-in-out, padding-right 200ms ease-in-out;

        &-text--top {
            font-weight: bold;
        }

        &-text--bottom {
            font-weight: lighter;
            margin-top: 4px;
        }

        &:visited {
            color: $white;
        }

        &:focus {
            color: $white;
        }

        & svg {
            & .a, .b {
                fill:none;
                stroke-linecap:round;
                stroke-linejoin:round;
            }
            
            .b {
                stroke:#fff;
                stroke-width:2px;
            }
            
            .c, .d {
                stroke:none;
                fill: $green;
            }
            
            .d {
                fill:#fff;
            }
        }

        &:hover {
            background-color: #fff;
            padding-right: 20px;

            & .presentations__next-btn-text--top {
                color: #000;
            }

            & .presentations__next-btn-text--bottom {
                color: $black;
            }

            & svg {
                .c, .d {
                    stroke:none;
                    fill:$black;
                }
            }

            & .arrow-a {
                fill: $black;
            }
        }

        & svg {
            min-width: 20px;
            margin-left: 10px;
        }
    }

    &__next-placeholder {
        background-color: $yellow;
        font-size: 1.6rem;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        text-decoration: none;
        text-transform: uppercase;
    }

    &__content-container {
        padding: 6%;
        color: $black;
        font-weight: 300;
    }

    &__welcome {
        display: flex;

        & > div:first-child {
            display: none;
        }

        & > div:nth-child(2) {
            width: 157px;
            margin-right: 40px;
        }
    }

    &__accordion {
        margin-top: 50px;
    }

    &__learn-more-list {
        list-style: none;
        padding-left: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        & li {
            flex-basis: 48%;
            margin-bottom: 20px;
        }
    }

    &__video-list {
        list-style: none;
        padding-left: 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        text-align: left;
        
        & h4 {
            margin-bottom: 10px;
        }

        & img {
            width: 100%;
            cursor: pointer;
        }

        & li {
            flex-basis: 48%;
        }

        & a {
            position: relative;
            display: block;

            // &:before {
            //     content: '';
            //     background-image: url("../../assets/playIcon.svg");
            //     background-repeat: no-repeat;
            //     position: absolute;
            //     top: 50%;
            //     left: 50%;
            //     transform: translate(-50%,-50%);
            //     height: 50px;
            //     width: 50px;
            // }
        }
    }

    &__embed-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        //z-index: 100;
    }

    &__hello {
        width: 157px;
        height: 157px;
        border-radius: 160px;
        background-color: $black;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 4.1rem;
        color: white;
        font-family: HeyWow;
        font-weight: normal;

      &--alt {
        width: 100px;
        height: 100px;
        border-radius: 160px;
        background-color: $black;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2.7rem;
        color: white;
        font-family: HeyWow;
        font-weight: normal;
        margin-bottom: 20px;
      }
    }
}

@media (max-width: $mobile) {
    .presentations {
        &__welcome {
            display: block;

            & > div:first-child {
                display: flex;
                align-items: center;

                & > div:first-child {
                    width: 120px;
                    position: relative;
                    margin-right: 20px;
                }

            }
    
            & > div:nth-child(2) {
                display: none;
            }
    
            & > div:last-child {
                & h2 {
                    display: none;
                }
            }
        }

        &__hello {
            width: 120px;
            height: 120px;
            font-size: 3.5rem;
            top: -64px;
            right: 10px;
        }

        &__bottom {
            display: none;
        }

        &__veil {
          align-items: flex-start;

          & > div {
            margin-top: 100px;
          }
        }

        &__learn-more-list {
          & li {
              flex-basis: 100%;
          }
      }
    }
}

@media (max-width: 400px) {
    .presentations {
        &__welcome {

            & > div:first-child {
                & > div:first-child {
                    width: 90px;
                    position: relative;
                    margin-right: 15px;
                    margin-top: -55px;

                    & img {
                        width: 90px;
                        height: 90px;
                    }
                }

            }
        }

        &__video-list {
            & li {
                flex-basis: 100%;
            }
        }
    }
}