html {
    font-size: 62.5%;
}

body, button {
    font-family: Gotham SSm A,Gotham SSm B,Helvetica;
}

@font-face {
    font-family: TTFirsNeue;
    font-weight: bold;
    src: url("../../fonts/TTFirsNeueBold.otf") format("opentype");
}

@font-face {
    font-family: HeyWow;
    font-weight: bold;
    src: url("../../fonts/HeyWow-Bold.otf") format("opentype");
}

@font-face {
    font-family: HeyWow;
    font-weight: normal;
    src: url("../../fonts/HeyWow-Medium.otf") format("opentype");
}