.splash {
    min-height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    background-color: $purple;

    &__welcome {
      width: 550px;
      color: #FFFFFF;
      font-size: 3rem;
      font-family: HeyWow;
      font-weight: normal;
      z-index: 10;
    }

    &__launch {
      color: #FFFFFF;
      font-size: 2.4rem;
      font-family: HeyWow;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      & br {
          display: none;
      }
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;

        &--container {
            width: 550px;
        }
    }

    &__border {
      &--desktop {
        position: absolute;
        top: 0;
        right: -184px;
        bottom: 0;
        height: 100%;
      }

      &--mobile {
        display: none;
      }
    }

    &__enter-btn {
        position: relative;
        background-color: $green;
        border-radius: 5px;
        font-family: HeyWow;
        color: #FFFFFF;
        text-decoration: none;
        padding: 12px 0;
        font-size: 3.5rem;
        display: block;
        width: 130px;
        text-align: center;
        margin-top: 20px;
        transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
        z-index: 10;

        &:hover {
            background-color: #fff;
            color: $green;
        }
    }

    & h1 {
        color: #FFFFFF;
        font-size: 10rem;
        line-height: 9.5rem;
        margin: 0 0 25px 0;
        font-family: HeyWow;
        font-weight: bold;
        position: relative;
        z-index: 10;

        & span:first-child {
            display: block;
            font-size: 3rem;
            line-height: 7rem;
            font-weight: normal;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        & span {
            white-space: nowrap;
            line-height: 80px;
        }
    }
  &__img {
    background-image: url("../../assets/Clark-Hall-students-Fall-2019-CC_027_JJ_v1.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@media (max-width: 1399px) {
  .splash {
    grid-template-columns: 1fr 40%;
  }
}

@media (max-width: 1199px) {
  .splash {
    grid-template-columns: 1fr 1fr;

    &__content {
      align-items: flex-start;
      padding: 20px 50px;
    }

    & h1 {
      font-size: 7vw;
      line-height: 6.8vw;
    }

    &__welcome {
      width: auto;
      font-size: 2.6vw;
    }

    &__content--container {
      width: auto;
    }

    &__enter-btn {
      width: auto;
      font-size: 2.8vw;
      padding: 12px 20px;
    }
  }
}

@media (max-width: 991px) {
  .splash {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;

    &__content {
      align-items: center;
      padding: 20px;
    }

    &__border {
      &--desktop {
        display: none;
      }

      &--mobile {
        display: block;
        position: absolute;
        left: 0;
        bottom: -192px;
        right: 0;
        width: 100%;
      }
    }

    & h1 {
      font-size: 10rem;
      line-height: 9.5rem;
    }

    &__welcome {
      width: 550px;
      font-size: 3rem;
    }

    &__content--container {
      width: 550px;
    }

    &__enter-btn {
      width: 130px;
      padding: 12px 0;
      font-size: 3.5rem;
    }
  }
}

@media (max-width: 767px) {
  .splash {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;

    &__content {
      align-items: flex-start;
      margin: auto;
      width: 100%;
      padding: 0px;

      &--inner {
        position: relative;
        margin: 0 auto;
        z-index: 10;
      }
    }

    &__border {
      &--mobile {
        display: block;
        position: absolute;
        left: 0;
        bottom: -220px;
        right: 0;
        width: 100%;
      }
    }

    & h1 {
      font-size: 13.5vw;
      line-height: 13vw;
    }

    &__welcome {
      width: auto;
      font-size: 4vw;
    }

    &__content--container {
      width: auto;
    }

    &__enter-btn {
      width: auto;
      font-size: 4.5vw;
      padding: 12px 20px;
      margin-top: 10px;
    }
  }
}