.mobile-accordion {
    margin-bottom: 20px;
    
    &__btn {
        color: #000;
        border: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        font-size: 1.9rem;
        text-align: left;
        padding: 15px;
        cursor: pointer;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        font-weight: 500;

        &[aria-expanded="false"] {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            color: $white;
        }

        &[aria-expanded="true"] .presentations__link-icon {
            border: 2px solid #000;
            background: #000;
            color: #fff;
        }

        &[aria-expanded="true"] .presentations__link-icon--hello {
            border: 2px solid #000;
            background: #000;
            color: #fff;
        }

        &[aria-expanded="true"] .presentations__link-icon--loc {
            border: 2px solid #000;
            background: #000;
            color: #fff;
        }
    }

    &__header {
        margin: 0;
    }

    &__body {
        background-color: $white;
        overflow: hidden;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }
}