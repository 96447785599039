.events {
    padding: 6%;
    color: $black;

    & a {
        color: #000;
        font-size: 1.6rem;
        text-decoration: none;
        font-weight: bold;

        &:visited {
            color: #000;
            text-decoration: none;
        }

        &:active {
            color: #000;
            text-decoration: none;
        }

        &:hover {
            color: #000;
            text-decoration: none;
        }
    }

    &__time {
        color: $blue;
        margin: 8px 0 10px 0;
        font-weight: 500 !important;
    }

    &__next-btn {
        margin: 50px 0 25px 0;
        color: $black !important;

        &:visited {
            color: $black !important;
            text-decoration: none;
        }

        &:active {
            color: $black !important;
            text-decoration: none;
        }

        &:hover {
            color: $black !important;
            text-decoration: none;
        }
    }

    &__sl {
        margin-bottom: 50px;
    }

    &__ad {
        background: $black;
        position: relative;
        padding: 15px 20px;
        padding-bottom: 80px;
        margin: 0 auto;
        max-width: 800px;

        & h2 {
            position: relative;
            font-size: 1.8rem;
            color: $white;
        }   

        & p {
            position: relative;
            color: $white;
            font-weight: 500 !important;
            font-size: 2.2rem;
            margin: 20px 0px 25px 0px !important;
        }

        & a {
            position: relative;
            text-transform: uppercase;
            color: $yellow;
            font-weight: bold;
            text-decoration: none;
            font-size: 1.6rem;

            &:hover {
                color: $yellow;
            }

            &:active {
                color: $yellow;
            }

            &:visited {
                color: $yellow;
            }
        }

        // &:before {
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     bottom: 20%;
        //     width: 40%;
        //     background: linear-gradient(to bottom right, $magenta 50%, $blue 50%);
        // }
    }

    &__ad-content-container {
        width: 80%;
        z-index: 10;
        position: relative;
    }

    &__ad-gg-logo-container {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        height: 140px;
        box-sizing: border-box;
    }

    &__ad-gg-logo {
        background-image: url("../../assets/g&glogo-1.svg");
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
    }

    & h2 {
        font-size: 2.2rem;
        line-height: 1.1;
        margin-bottom: 0;
        margin-top: 50px;
        
        &:first-child {
            margin-top: 0;
        }
    }

    & p {
        font-size: 1.6rem;
        line-height: 1.5;
        font-weight: 300;
    }
}

@media (max-width: 1000px) {
    .events {
        &__ad {
            padding: 25px;

            &:before {
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 128px;
            }
        }

        &__ad {
            max-width: 400px;
            padding: 0;
            display: flex;
            flex-direction: column;
        }

        &__ad-content-container {
            text-align: center;
            margin-bottom: 20px;
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
        }

        &__ad-gg-logo-container {
            position: relative;
        }

        &__ad-gg-logo {
            background-image: url("../../assets/gglogo-mobile.svg");
        }
    }
}