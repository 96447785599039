.resources {
    width: 300px;
    height: 100%;
    position: relative;
    font-weight: 300;
    box-shadow: 0px 0px 20px #00000040;

    &--closed {
        & .resources__close-btn--closed {
            display: none;
        }

        & .resources__close-btn--opened {
            display: block;
        }
    }

    &--opened {
        & .resources__close-btn--closed {
            display: block;
        }

        & .resources__close-btn--opened {
            display: none !important;
        }
    }

    &__label {
        writing-mode: tb-rl;
        height: 100%;
        font-size: 2rem;
        color: #000000;
        margin-left: 7px;
        padding-top: 20px;
        display: flex;
        letter-spacing: 0.5px;
        font-family: HeyWow;
    }

    &__icon {
        & svg {
            width: 23px;
            height: 17px;
        }
    }

    &__container {
        padding: 15px 20px 20px 20px;
        height: 100%;
        box-sizing: border-box;
        overflow: auto;
    }

    &__close-btn {
        border: none;
        background-color: rgba(0,0,0,0);
        cursor: pointer;
        z-index: 10;
        stroke: $green;
        transition: 
            background-color 300ms ease-in-out, 
            color 300ms ease-in-out;
    }

    &__close-btn--closed {
        width: 53px;
        position: absolute;
        top: 10px;
        left: 15px;
        margin-left: -15px;
        margin-top: -10px;
        padding-top: 20px;
        background-color: $purple;
        stroke: #fff;

        & .resources__label {
          color: #fff;
        }

        &:hover {
            background-color: #fff;
            stroke: $purple;
            
            & .resources__label {
                color: #000000;
            }
        }
    }

    &__close-btn--opened {
        padding-left: 0;
        height: 40px;
        border-radius: 3px;
        color: #fff;

        & span {
            display: none;
            white-space: nowrap;
            padding-left: 10px;
        }

        &--container {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
        }

        &:hover {
            background-color: $green;
            stroke: #fff;

            & span {
                display: inline-block;
            }
        }
    }

    &__hr {
        width: 40px;
        margin-top: 22px;

        & hr {
            border: none;
            border-top: 1px solid #E1E1E1;
        }

        &--inner {
            border: none;
            border-top: 1px solid #E1E1E1;
            margin-top: 15px;
            margin-bottom: 20px;
        }
    }

    &__h2 {
        font-size: 1.8rem;
        font-weight: bold;
        margin-top: 30px;
    }

    &__list {
        list-style: none;
        padding-left: 0px;
        margin: 0;

        & li {
            margin-bottom: 20px;
        }

        & a {
            color: $green;
            font-size: 1.4rem;
            text-decoration: none;
            font-weight: bold;

            &:visited {
                color: $green;
                text-decoration: none;
            }

            &:active {
                color: $green;
                text-decoration: none;
            }

            &:hover {
                color: $green;
                text-decoration: underline;
            }
        }
    }

    &__mobile-title {
        display: flex;
        height: 70px;
        align-items: center;
    }

    &__mobile-body {
        padding: 30px 15px 25px 15px;
    }
}

@media (max-width: 1300px) {
    .resources {
        width: 325px;
    }
}

@media (max-width: $mobile) {
    .resources {
        width: 100%;

        &__icon-container {
            margin-top: 10px;
        }

        &__close-btn {
            position: relative;
            top: auto;
            left: auto;
            border: none;
            background-color: rgba(0,0,0,0);
            cursor: pointer;
            z-index: 10;
            margin-left: 15px;
            display: flex;
            width: 100%;
        }

        &__container {
            padding-bottom: 100px;
        }

        &__label {
            writing-mode: horizontal-tb;
            margin-left: 0px;
            border-left: 1px solid #E1E1E1;
            padding-top: 0px;
            height: 40px;
            padding-left: 25px;
            margin-left: 15px;
            display: flex;
            align-items: center;
        }

        &__hr {
            &--inner {
                display: none;
            }
        }
    }
}