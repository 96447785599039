.accordion {
    margin-bottom: 20px;
    
    &__btn {
        border: 2px solid #E1E1E1;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        background-color: #FFFFFF;
        color: #000000;
        font-family: HeyWow;
        font-size: 1.6rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        text-align: left;
        padding: 15px;
        cursor: pointer;

        & img {
            margin-right: 10px;
            height: 17px;
        }

        &[aria-expanded="false"] {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
        }
    }

    &__header {
        margin: 12px 0 0 0 !important;
    }

    &__container--body {
        padding: 15px 15px 5px 15px;
    }

    &__body {
        background-color: $white;
        overflow: hidden;
        border-right: 2px solid #E1E1E1;
        border-bottom: 2px solid #E1E1E1;
        border-left: 2px solid #E1E1E1;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }
}