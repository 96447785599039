.schedule {
    background-color: #EDEDED;

    &__page {
        background-color: #EDEDED !important;
    }

    &__header {
        background-color: #000;
        color: #fff;
        font-family: HeyWow;
        font-weight: bold;
        font-size: 2.6rem;
        height: 65px;
        display: flex;
        align-items: center;
        padding-left: 6%;
    }

    &__body {
        padding: 6%;
    }

    &__card {
        background-color: #FFFFFF;
        border-radius: 3px;
        padding: 5px 25px 10px 25px;
        margin-bottom: 15px;
        --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

        & h2 {
            font-family: HeyWow;
            font-weight: bold;
            font-size: 2rem;
        }

        & hr {
            border: none;
            border-top: 1px solid #F0F0F0;
            margin-bottom: 15px;
        }

        & ul {
            list-style: none;
            padding-left: 0px;
        }

        & p {
          font-size: 1.5rem;
          color: gray;
        }
    }
}

@media (max-width: $mobile) {
    .schedule {
        background-color: #fff;

        &__header {
            display: none;
        }

        &__card {
            border-radius: 0;
            padding: 0;
            box-shadow: none;

            & h2 {
                color: #000;
            }
        }
    }
}